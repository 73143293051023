import logo from './logo.svg';
import './App.css';

import OneSignal from 'react-onesignal';

OneSignal.init({ appId: '8e4f35d2-4576-43d7-bb72-a351758557c4' });

//Example1
await OneSignal.init({ appId: '8e4f35d2-4576-43d7-bb72-a351758557c4' });
// do other stuff

//Example2  // do other stuff
/*
const [initialized, setInitialized] = useState(false);
OneSignal.init({ appId: 'xxxxxxxx-xxxx-xxxx-xxxx-xxxxxxxxxxxx' }).then(() => {
  setInitialized(true);
  OneSignal.Slidedown.promptPush();
 
})
*/



/* LOCALHOST

import OneSignal from 'react-onesignal';

export default async function runOneSignal() {
  await OneSignal.init({ appId: 'xxxxxxxx-xxxx-xxxx-xxxx-xxxxxxxxxxxx', allowLocalhostAsSecureOrigin: true});
  OneSignal.Slidedown.promptPush();
}


import runOneSignal from './onesignal';
import { useEffect } from 'react';

function App() {
  useEffect(() => {
    runOneSignal();
  })

  */



function App() {
  return (
    <div className="App">
      <header className="App-header">
        <img src={logo} className="App-logo" alt="logo" />
        <p>
          Edit <code>src/App.js</code> and save to reload.
        </p>
        <a
          className="App-link"
          href="https://reactjs.org"
          target="_blank"
          rel="noopener noreferrer"
        >
          Learn React
        </a>
      </header>
    </div>
  );
}

export default App;
